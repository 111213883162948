import '@unocss/reset/tailwind.css'
import 'virtual:uno.css'
import 'virtual:unocss-devtools'
import 'notyf/notyf.min.css'
import '~/lib/subscriptions'

import '~/lib/browser.css'

import { hydrate, mount } from 'svelte'
import { createInertiaApp, router } from 'inertiax-svelte'
import { Notyf } from 'notyf';
import axios from 'axios'

import resolve from '../lib/resolve'

window.notyf = new Notyf({
  position: {
    x: 'center',
    y: 'bottom'
  },
  duration: 3500
})

const csrfToken = document.querySelector('meta[name=csrf-token]').content
axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken

createInertiaApp({ 
  resolve,
  setup({ el, App, props }) {
    hydrate(App, { target: el, props })
  },
})

window.addEventListener('message', async function(event) {
  if (event.data == 'logged_in') {
    router.reload({preserveURL: false})
  }
})