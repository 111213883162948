import { subscribe, unsubscribe, setStore } from '~/lib/livestores'
import { page } from 'inertiax-svelte'
import { derived } from 'svelte/store'
import { getStore } from './livestores'

const balances = getStore('balances', {})
const orders = getStore('orders', [])

export const portfolio = derived([balances, orders], ([$balances, $orders]) => {
  const fundsInOrders = $orders.filter(order => !order.cancelled && !order.completed).reduce((acc, order) => {
    const { remaining_sell_amount, sell_symbol } = order
    if (sell_symbol == 'eur') return acc + parseFloat(remaining_sell_amount)
    // TODO: sum up other currencies, assets
    return acc
  }, 0)

  const investmentsEur = 0
  
  return {
    fundsInOrders,
    totalEur: parseFloat($balances.eur) + investmentsEur
  }
})

page.subscribe(function($page){
  if ($page.props?.current_user) {
    const { current_user } = $page.props
    subscribe('UserChannel')
    setStore('current_user', current_user)
  }
  else unsubscribe('UserChannel')
})